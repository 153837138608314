import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useEmployees() {
  const employees = ref([])
  const employee = ref({})
  const roles = ref([])
  const permissions = ref([])
  const loading = ref(false)
  const searchEmployee = ref(null)

  const getEmployees = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/users/filter', params)
        .then(response => {
          const { data } = response.data
          employees.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getRoles = () => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get('/users/roles')
        .then(response => {
          const { data } = response
          roles.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getPermissions = () => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get('/users/permissions')
        .then(response => {
          const { data } = response
          permissions.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getEmployee = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/users/${id}`)
        .then(response => {
          const { data } = response
          employee.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const fetchEmployees = () => {
    getEmployees()
  }

  return {
    // variables
    loading,
    searchEmployee,
    employees,
    employee,
    roles,
    permissions,

    // functions
    getEmployees,
    getEmployee,
    fetchEmployees,
    getRoles,
    getPermissions,
  }
}
