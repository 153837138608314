import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useVehicles() {
  const vehicles = ref([])
  const loading = ref(false)
  const searchVehicle = ref(null)
  const vehicle = ref({})

  const getVehicles = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/vehicles/filter', params)
        .then(response => {
          const { data } = response.data
          vehicles.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getVehicle = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/vehicles/${id}`)
        .then(response => {
          const { data } = response
          vehicle.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    // vars
    loading,
    searchVehicle,
    vehicles,
    vehicle,

    // functions
    getVehicles,
    getVehicle,
  }
}
