<template>
  <v-card>
    <v-card-title>Vehicle</v-card-title>
    <v-card-text>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-autocomplete
              v-model="vehicleForm.make_id"
              aria-required=""
              placeholder="Search.. ."
              :loading="loadingMake"
              :error-messages="errors.make_id"
              :items="makes"
              :search-input.sync="searchMake"
              cache-items
              hide-details="auto"
              label="Make"
              item-text="name"
              item-value="id"
              outlined
              dense
              @change="vehicleForm.make_model_id = null; models=[]"
              @keyup.enter="fetchMakes"
            ></v-autocomplete>
          </v-col>
          <v-col
            v-if="vehicleForm.make_id"
            cols="12"
            md="12"
          >
            <v-autocomplete
              v-model="vehicleForm.make_model_id"
              :error-messages="errors.make_model_id"
              placeholder="Search.. ."
              :loading="loadingModel"
              :items="models"
              :search-input.sync="searchModel"
              hide-details="auto"
              label="Model"
              item-text="name"
              item-value="id"
              outlined
              dense
              @keyup.enter="fetchModels"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="vehicleForm.vehicle_type_id"
              placeholder="Search.. ."
              :loading="loadingType"
              :items="types"
              :search-input.sync="searchType"
              cache-items
              hide-details="auto"
              label="Type"
              item-text="type"
              item-value="id"
              outlined
              dense
              clearable
              @keyup.enter="fetchTypes"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="vehicleForm.color"
              label="Color"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="vehicleForm.plate_number"
              :error-messages="errors.plate_number"
              label="Plate Number"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="vehicleForm.engine_number"
              label="Engine Number"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="vehicleForm.acquisition_cost"
              label="Acquisition Cost"
              outlined
              dense
              hide-details="auto"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-menu
              v-model="acquisitionDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="vehicleForm.acquisition_date"
                  label="Acquisition Date"
                  :prepend-icon="icons.mdiCalendar"
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="vehicleForm.acquisition_date"
                color="primary"
                @input="acquisitionDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-autocomplete
              v-model="vehicleForm.department"
              placeholder="Search.. ."
              :loading="loadingDepartment"
              :error-messages="errors.department"
              :items="departmentOptions"
              :search-input.sync="searchDepartment"
              cache-items
              hide-details="auto"
              label="Department"
              item-text="name"
              outlined
              dense
              hide-no-data
              return-object
              @keyup.enter="fetchDepartments"
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-autocomplete
              v-model="vehicleForm.driver_id"
              aria-required=""
              placeholder="Search.. ."
              :loading="loadingEmployee"
              :items="employees"
              :search-input.sync="searchEmployee"
              cache-items
              hide-details="auto"
              label="Driver"
              item-text="full_name"
              item-value="id"
              outlined
              dense
              hide-no-data
              clearable
              @keyup.enter="fetchEmployees(['Driver'])"
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="vehicleForm.condition"
              :items="conditions"
              hide-details="auto"
              label="Unit Condition"
              outlined
              dense
              hide-no-data
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-btn
              v-if=" $store.getters['auth/can'](['create vehicles'])"
              color="primary"
              :disabled="saving"
              @click="saveForm"
            >
              Save
            </v-btn>
            <v-btn
              outlined
              class="mx-2"
              @click="$router.back()"
            >
              Back
            </v-btn>
            <v-btn
              outlined
              class="mx-2"
              @click="resetForm"
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'
import axios from '@axios'
import useMakes from './useMakes'
import useTypes from './useTypes'
import useVehicles from './useVehicles'
import useModels from './useModels'
import router from '@/router'
import useDepartments from '../job-orders/useDepartments'
import useEmployees from '../employees/useEmployees'

const formInit = () => ({
  id: null,
  make_id: null,
  make_model_id: null,
  vehicle_type_id: null,
  engine_number: '',
  plate_number: '',
  acquisition_date: new Date().toISOString().substr(0, 10),
  acquisition_cost: null,
  color: '',
  department: null,
  driver_id: null,
  condition: 'Running',
})

export default {
  setup() {
    const saving = ref(false)
    const errors = ref([])
    const loadingEmployee = ref(false)
    const acquisitionDatePicker = ref(false)
    const vehicleForm = ref(formInit())
    const { makes, getMakes, loading: loadingMake, searchMake } = useMakes()
    const { models, getModels, loading: loadingModel, searchModel } = useModels()
    const { types, getTypes, loading: loadingType, searchType } = useTypes()
    const { vehicle, getVehicle } = useVehicles()
    const {
      departments: departmentOptions,
      getDepartments,
      searchDepartment,
      loading: loadingDepartment,
    } = useDepartments()
    const { employees, getEmployees, searchEmployee } = useEmployees()
    const resetForm = () => {
      vehicleForm.value = formInit()
    }

    const conditions = ref(['Running', 'Under Maintenance', 'Not running'])

    if (router.currentRoute.params.id) {
      saving.value = true

      // fetch vehicle from the server
      getVehicle(router.currentRoute.params.id).then(response => {
        const { data } = response
        vehicleForm.value = data

        // assign temp value(s) for autocompletes
        makes.value = [data.make]
        models.value = [data.make_model]
        if (data.vehicle_type) {
          types.value = [data.vehicle_type]
        }

        if (data.driver) {
          employees.value = [data.driver]
        }
        const department = {
          name: data.department_name,
          id: data.department_id,
        }
        departmentOptions.value = data.department_id ? [department] : []
        vehicleForm.value.department = department

        saving.value = false
      })
    }

    const fetchDepartments = () => {
      getDepartments({ search: searchDepartment.value })
    }

    const fetchMakes = () => {
      getMakes({ searchQuery: searchMake.value })
    }

    const fetchModels = () => {
      getModels({ make_id: vehicleForm.value.make_id, searchQuery: searchModel.value })
    }

    const fetchTypes = () => {
      getTypes({ searchQuery: searchType.value })
    }

    const fetchEmployees = roles => {
      loadingEmployee.value = true
      getEmployees({ roles, searchQuery: searchEmployee.value }).then(() => {
        loadingEmployee.value = false
      })
    }

    const saveForm = () => {
      saving.value = true
      if (vehicleForm.value.id) {
        // update
        axios
          .put(`/vehicles/${vehicleForm.value.id}`, vehicleForm.value)
          .then(_ => {
            saving.value = false
            router.back()
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      } else {
        // new
        axios
          .post('/vehicles', vehicleForm.value)
          .then(_ => {
            saving.value = false
            router.back()
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      }
    }

    return {
      // variables
      errors,
      conditions,
      saving,

      // functions
      saveForm,
      fetchMakes,
      fetchModels,
      fetchTypes,

      // useVehicles
      vehicle,
      getVehicle,

      // useEmployees
      employees,
      getEmployees,
      searchEmployee,
      loadingEmployee,
      fetchEmployees,

      // useDepartments
      departmentOptions,
      fetchDepartments,
      searchDepartment,
      loadingDepartment,

      // useMakes
      makes,
      getMakes,
      loadingMake,
      searchMake,

      // useModels
      models,
      getModels,
      loadingModel,
      searchModel,

      // useTypes
      types,
      getTypes,
      loadingType,
      searchType,

      acquisitionDatePicker,
      vehicleForm,

      resetForm,

      // icons
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
